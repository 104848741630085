exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-body-js": () => import("./../../../src/pages/body.js" /* webpackChunkName: "component---src-pages-body-js" */),
  "component---src-pages-hair-js": () => import("./../../../src/pages/hair.js" /* webpackChunkName: "component---src-pages-hair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-skin-js": () => import("./../../../src/pages/skin.js" /* webpackChunkName: "component---src-pages-skin-js" */)
}

